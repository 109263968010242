//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Activity",
  data() {
    return {};
  },
  computed: {
    query() {
      return window.location.search;
    }
  }
};
